import React from 'react';
import { graphql } from 'gatsby';
import { Helmet } from 'react-helmet';
import Layout from '../components/layout';
import './index.css';
class BlogPostContentfulTemplate extends React.Component {
  render() {
    const post = this.props.data.contentfulPost;

    return (
      <Layout location={this.props.location}>
        <Helmet title={post.title} />
        <div className='news_content'>
          <div className='news__container'>
            <div className='wrapper'>
              {post.image.map(edge => (
                <img className='header-image' src={edge.resize.src} />
              ))}
              <h1 className='section-headline'>{post.title}</h1>
              <div className=''>
                <p className='post-owner'>
                  By <span className='author-name'>{post.author}</span>
                </p>
                <p className=''>{post.date}</p>
              </div>
              <div
                dangerouslySetInnerHTML={{
                  __html: post.content.childContentfulRichText.html
                }}
              />
            </div>
          </div>
        </div>
        <p>{post.createdAt}</p>
      </Layout>
    );
  }
}

export default BlogPostContentfulTemplate;

export const pageQuery = graphql`
  query ContentfulBlogPostByTitle($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    contentfulPost(slug: { eq: $slug }) {
      title
      author
      date(formatString: "MM/DD/YYYY")
      slug
      image {
        resize(width: 800, height: 300) {
          src
        }
      }
      content {
        childContentfulRichText {
          html
        }
      }
    }
  }
`;
